<template>
  <div class="company">
    <van-form>
      <div class="group">
        <van-field
          v-model="form.company_name"
          name="商家名称"
          label="商家名称"
          placeholder="请输入商家名称"
          :rules="[{ required: true, message: '请填写商家名称' }]"
        />
        <van-field
          readonly
          clickable
          :value="form.cat_id2Text"
          label="服务分类"
          placeholder="点击选择服务分类"
          @click="showType = true"
        />
      </div>

      <div class="group">
        <van-field
          v-model="form.company_key"
          name="关键字"
          label="关键字"
          placeholder="请输入行业关键字"
          :rules="[{ required: true, message: '请输入行业关键字' }]"
        />
        <van-field
          v-model="showAddr"
          is-link
          readonly
          label="所在区域"
          placeholder="请选择所在地区"
          @click="showArea = true"
        />
        <van-field
          v-model="form.company_address"
          label="详情地址"
          right-icon="location-o"
          placeholder="请输入地址或点击地图选择"
          @click-right-icon="selectAddr"
        />
      </div>

      <div class="group">
        <van-field
          v-model="form.store_person_name"
          name="联 系 人"
          label="联 系 人"
          placeholder="请输入联系人"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          v-model="form.contacts_mobile"
          name="联系电话"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
      </div>

      <div class="group">
        <van-row>
          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="logoImg" :max-count="1" :after-read="uploadImg" />
            <div>商家LOGO(200*200)</div>
          </van-col>

          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="wechatImg" multiple :max-count="1" :after-read="uploadImg" />
            <div>老板微信(200*200)</div>
          </van-col>
        </van-row>
      </div>

      <div class="group">
        <van-row>
          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="bizLicenseImg" multiple :max-count="1" :after-read="uploadImg" />
            <div>营业执照</div>
          </van-col>

          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="idImg" multiple :max-count="1" :after-read="uploadImg" />
            <div>法人身份证人像面</div>
          </van-col>
        </van-row>
      </div>

      <div class="group">
        <van-panel title="商家轮播图(750*300)" desc="" status="">
          <van-uploader v-model="slideshowImg" multiple :max-count="9" :after-read="uploadImg" />
        </van-panel>
      </div>

      <div class="group">
        <van-panel title="商家介绍" desc="" status="">
          <van-field
            type="textarea"
            v-model="form.info"
            name="商家介绍"
            autosize
            placeholder="请输入商家介绍"
            :rules="[{ required: true, message: '请填写商家介绍' }]"
          />
        </van-panel>
        <van-panel title="商家详情图(750*300)" desc="" status="">
          <van-uploader v-model="contentImg" multiple :max-count="9" :after-read="uploadImg" />
        </van-panel>
      </div>
      <div class="group">
        <van-field
          v-model="form.mobile"
          name="手 机 号"
          label="手 机 号"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="form.code"
          name="验 证 码"
          label="验 证 码"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button size="small" type="danger" @click="getSmsCode" :disabled="verifyStatus">{{
              verifyText
            }}</van-button>
          </template>
        </van-field>

        <van-row style="background-color: #fff;font-size: 14px; color:#646566; line-height: 24px; padding-top:10px;">
          <van-col span="8" style="padding-left: 13px;">服务时间</van-col>
          <van-col span="8"
            >一年：收费 <span style="color: #f00; font-size: 14px;"> {{ membershipFee }} </span>元</van-col
          >
        </van-row>
      </div>

      <van-checkbox name="1" checked-color="#ee0a24" v-model="form.shouldKnow" class="company-know"
        >我已阅读并同意 <span @click="showKnowTips">《商家入驻须知》</span></van-checkbox
      >
      <div style="text-align: center; margin: 30px;">
        <van-button type="primary" style="padding: 0 50px;" @click="onSubmit">去认证</van-button>
      </div>

      <van-popup v-model="showType" position="bottom">
        <van-picker
          show-toolbar
          value-key="name"
          v-model="selectedType"
          :columns="typeList"
          @confirm="onSelectType"
          @cancel="showType = false"
        />
      </van-popup>
      <van-popup v-model="isShowTips" closeable close-icon="close" position="bottom">
        <div :style="companyKnowStyle" class="know-Container">商家须知</div>
      </van-popup>
      <van-popup v-model="showArea" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="citylist"
          @change="onChange"
          @close="show = false"
          @finish="onFinish"
        />
      </van-popup>
    </van-form>
    <!--选择地点-->
    <place-search @btnout="btnout" class="place-search" id="place-search" ref="homeSearch"></place-search>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PlaceSearch from '@/views/common/map/AMap'
import { companyApply, getCompanyStatus, getCompanyEidt } from '@/network/company'
import { getCityList } from 'network/nearby'
import { getType, sendSmsCode } from 'network/home'
import { uploadBase64 } from '@/network/publish'

export default {
  name: 'apply',
  components: {
    PlaceSearch
  },
  data() {
    return {
      selectedType: '',
      typeList: [],
      showType: false,
      serviceType: '',
      showArea: false,
      isShowTips: false,
      companyKnowStyle: '',
      verifyStatus: false,
      verifyText: '获取验证码',
      membershipFee: 0,
      showAddr: '',
      cascaderValue: '',
      citylist: [],
      logoImg: [],
      wechatImg: [],
      idImg: [],
      bizLicenseImg: [],
      slideshowImg: [],
      contentImg: [],
      form: {
        shouldKnow: false,
        company_name: '', //	商家名称
        sc_id: '',
        cat_id2: '', //	服务类型
        cat_id2Text: '',
        company_province: '', //	所在省
        company_city: '', //	所在市
        company_district: '', //	所在区
        company_key: '', //	服务关键字
        company_address: '', //	详细地址
        store_person_name: '', //	联系人
        contacts_mobile: '', //联系电话
        mobile: '',
        code: '',
        store_logo: '', //	LOGO图片
        weixin_img: '', //	板微信图片
        business_licence_cert: '', //营业执照图片
        legal_identity_cert: '', //	身份证图片
        info: '', //商家介绍
        store_slide: [], //商家轮播图 （数组类型）
        store_infoimg: [], //商家详情图（数组类型）
        longitude: '', // 经度

        latitude: '' //纬度
      }
    }
  },
  computed: {
    ...mapState(['baseURL'])
  },
  created() {
    this.init()
    this.companyKnowStyle = 'width:' + (document.documentElement.clientWidth - 60) + 'px;'
    this.companyKnowStyle += 'height:' + (document.documentElement.clientHeight - 100) + 'px;'
  },
  methods: {
    init() {
      const _self = this
      //初化类型
      getType().then((res) => {
        _self.typeList = res.data.result
      })
      //获取认证费
      getCompanyStatus().then((res) => {
        if (res.data) {
          _self.membershipFee = res.data.membership_fee
        }
      })
      //初始化城市列表
      this.getCityList(this.citylist)

      //如果是修改，加载企业信息

      getCompanyEidt().then((res) => {
        if (res && res.data.status == 1 && res.data.result) {
          const store = res.data.result.store
          _self.form = store

          console.log(res.data.result)
          _self.selectedType = store.sc_name
          _self.form.sc_id = store.sc_id
          _self.form.cat_id2 = store.sc_id
          _self.form.cat_id2Text = store.sc_name
          _self.form.info = store.info.split('<br/>').join('\n')

          _self.showAddr = store.province + store.city + store.district_name
          //this.content = this.content.replace(/\n/g, '<br/>');
          //this.content = this.content.replace(/\s/g, ' '); //空格处理

          _self.idImg[0] = {}
          _self.idImg[0].url = _self.baseURL + _self.form.legal_identity_cert
          _self.idImg[0].filePath = _self.form.legal_identity_cert

          _self.bizLicenseImg[0] = {}
          _self.bizLicenseImg[0].url = _self.baseURL + _self.form.business_licence_cert
          _self.bizLicenseImg[0].filePath = _self.form.business_licence_cert

          _self.logoImg[0] = {}
          _self.logoImg[0].url = _self.baseURL + _self.form.store_logo
          _self.logoImg[0].filePath = _self.form.store_logo

          _self.wechatImg[0] = {}
          _self.wechatImg[0].url = _self.baseURL + _self.form.weixin_img
          _self.wechatImg[0].filePath = _self.form.weixin_img
          var i
          //轮播图
          for (i = 0; i < _self.form.store_slide.length; i++) {
            _self.slideshowImg[i] = {}
            _self.slideshowImg[i].url = _self.baseURL + _self.form.store_slide[i]
            _self.slideshowImg[i].filePath = _self.form.store_slide[i]
          }
          //商家详情图
          for (i = 0; i < _self.form.store_infoimg.length; i++) {
            _self.contentImg[i] = {}
            _self.contentImg[i].url = _self.baseURL + _self.form.store_infoimg[i]
            _self.contentImg[i].filePath = _self.form.store_infoimg[i]
          }
        }
        if (!_self.form.mobile || _self.form.mobile == '') {
          //加载默认手机号
          const userInfo = JSON.parse(_self.$store.state.userInfoJSON)
          if (userInfo) {
            _self.form.mobile = userInfo.mobile
          }
        }
      })
    },
    btnout(item) {
      if (item) {
        this.form.company_address = item.name
        this.form.longitude = item.location.lng
        this.form.latitude = item.location.lat
      }
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 100 + 'vh'
      placesearch.style.height = 0 + 'px'
    },
    onSelectType(typeObj) {
      this.form.sc_id = typeObj.id
      this.form.cat_id2 = typeObj.id
      this.form.cat_id2Text = typeObj.name
      this.showType = false
    },
    showKnowTips() {
      this.isShowTips = true
    },
    getCityList(citylist, value) {
      getCityList(value).then((res) => {
        if (res.data.status == 1 && res.data.result) {
          res.data.result.data.forEach((item) => {
            item.value = item.id
            item.text = item.name
            if (item.level < 3) {
              item.children = []
            }
            citylist.push(item)
          })
        }
      })
    },
    onChange({ value, selectedOptions }) {
      if (selectedOptions.length < 3) {
        selectedOptions[selectedOptions.length - 1].children = []
        this.getCityList(selectedOptions[selectedOptions.length - 1].children, value)
      }
    },
    onFinish({ selectedOptions }) {
      this.showArea = false
      this.showAddr = selectedOptions.map((option) => option.text).join('/')

      this.form.company_province = selectedOptions[0].id
      this.form.company_city = selectedOptions[1].id
      this.form.company_district = selectedOptions[2].id
    },
    selectAddr() {
      /*
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 0 + 'vh'
      const h = document.documentElement.clientHeight
      placesearch.style.height = h + 'px'
      */
    },
    getSmsCode() {
      const mobile = this.form.mobile
      if (!mobile || !/^1[3|4|5|7|8]\d{9}$/.test(mobile)) {
        this.$toast('手机号为空或格式错误')
        return
      }

      sendSmsCode(mobile).then((res) => {
        if (res.data && res.data.status === 1) {
          this.$toast('信息发送成功')

          this.verifyStatus = true
          this.countDown()
        }
      })
    },
    countDown() {
      var time = 60
      var timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.verifyStatus = false
          this.verifyText = '获取验证码'
        } else {
          this.verifyStatus = true
          this.verifyText = `重新获取(${time}s)`
          time--
        }
      }, 1000)
    },
    uploadImg(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((file) => {
        uploadBase64(file.file.name, file.content).then((res) => {
          if (res.data && res.data.status === 1) {
            file.filePath = res.data.result[0].img
          }
        })
      })
    },
    onSubmit() {
      if (
        !this.form.company_name ||
        !this.form.contacts_mobile ||
        !this.form.store_person_name ||
        !this.form.company_key ||
        !this.form.info ||
        !this.form.mobile ||
        !this.form.code ||
        !this.form.company_province
      ) {
        return false
      }
      if (this.logoImg.length < 1) {
        this.$message('请上传LOGO图片')
        return false
      }
      if (this.wechatImg.length < 1) {
        this.$message('请上传老板微信二维码')
        return false
      }
      if (this.bizLicenseImg.length < 1) {
        this.$message('请上传营业执照图片')
        return false
      }
      if (this.idImg.length < 1) {
        this.$message('请上传身份信息图')
        return false
      }
      if (this.slideshowImg.length < 1) {
        this.$message('请上传轮播图')
        return false
      }
      if (this.contentImg.length < 1) {
        this.$message('请上传商家详情图')
        return false
      }
      if (!this.form.shouldKnow) {
        this.$message('请阅读并同意《商家入驻须知》')
        return false
      }

      this.logoImg.forEach((file) => {
        this.form.store_logo = file.filePath
      })

      this.wechatImg.forEach((file) => {
        this.form.weixin_img = file.filePath
      })

      this.bizLicenseImg.forEach((file) => {
        this.form.business_licence_cert = file.filePath
      })

      this.idImg.forEach((file) => {
        this.form.legal_identity_cert = file.filePath
      })

      this.form.store_slide = []
      this.slideshowImg.forEach((file) => {
        this.form.store_slide.push(file.filePath)
      })

      this.form.store_infoimg = []
      this.contentImg.forEach((file) => {
        this.form.store_infoimg.push(file.filePath)
      })

      companyApply(this.form).then((res) => {
        if (res.data && res.data.status == 1) {
          this.$router.push('/companyPay')
        } else {
          this.$toast.fail(res.data.msg)
        }
      })
    }
  }
}
</script>

<style>
body {
  background-color: #efeff4;
}
.van-uploader__preview {
  margin: 8px;
}
.van-uploader__upload {
  margin: 8px;
}
</style>
<style scoped>
.company {
  margin-bottom: 100px;
}
.company .item {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

.company .group {
  padding: 10px;
  margin-bottom: 8px;
  font-size: 10px;
  color: #999;
  background-color: #fff;
}
.place-search {
  position: fixed;
  width: 100vh;
  height: 1px;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}
.company-know {
  margin-top: 10px;
  font-size: 14px;
  padding: 10px 16px;
}
.company-know span {
  color: #ff0000;
}
.know-Container {
  margin: 50px 27px;
}
</style>
